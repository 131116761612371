<template>
  <div class="container">
    <PublicHeader :totalName="totalName" />
    <div class="content">
      <van-field
        v-model="userName"
        label="真实姓名:"
        :border="false"
        placeholder="请输入真实姓名"
        input-align="right"
      />
      <van-field
        v-model="CardId"
        label="开户行:"
        :border="false"
        placeholder="请输入开户行号"
        input-align="right"
      />
      <van-field
        v-model="CardId"
        label="银行卡号:"
        :border="false"
        placeholder="请输入银行卡号"
        input-align="right"
      />
      <van-field
        v-model="CardId"
        label="银行地址:"
        :border="false"
        placeholder="请输入银行地址"
        input-align="right"
      />
      <div class="btn">确认</div>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      totalName: '收款方式',
      userName: '',
      CardId: ''
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 45px;
  .content {
    margin: 10px 20px;
    .van-field {
      background: transparent;
      border: none;
      & /deep/ .van-field__control,
      & /deep/ .van-field__label {
        color: #fff;
        font-size: 12px;
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px auto 0;
      height: 40px;
      border-radius: 18px;
      width: 100%;
      background: #e143ac;
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>
